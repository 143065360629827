var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Customers"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Alerts","sub-heading":"A list of all alerts you are subscribed to."}})],1),_c('column',{staticClass:"is-5"},[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search for alerts..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.$route.query.search)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),_c('router-link',{staticClass:"button is-rounded",attrs:{"to":{
                                name: 'create-alert'
                            }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Alert")])],1)],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.alerts.data),function(alert){return _c('div',{key:alert.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{staticClass:"mr-2",attrs:{"to":{
                                name: 'edit-alert',
                                params: {
                                    alert: alert.id
                                }
                            }}},[_vm._v(" "+_vm._s(_vm._f("ucwords")(_vm._f("replace")(alert.type,'_', ' ')))+" ")]),_c('small',{staticClass:"has-text-grey"},[_vm._v("Updated "+_vm._s(_vm._f("asCalendar")(alert.updated_at)))])],1),_c('column',{staticClass:"is-1 has-text-right"},[_vm._v(" "+_vm._s(alert.events.length)+" Event"),(alert.events.length !== 1)?_c('span',[_vm._v("s")]):_vm._e()]),_c('column',{staticClass:"is-1 has-text-right"},[_vm._v(" "+_vm._s(_vm._f("ucwords")(alert.schedule.schedule))+" ")]),_c('column',{staticClass:"is-1 has-text-right"},[_c('outcome-tag',{attrs:{"status":alert.status}})],1)],1)],1)}),0),(_vm.alerts.data.length)?_c('pager',{attrs:{"pageable":_vm.alerts,"context":"Equipment","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.alerts.data.length,"heading":"There are no alerts available for display."}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }