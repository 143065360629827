<template>
    <loader v-bind="{ loading }" text="Loading Customers">
        <columns>
            <column>
                <page-heading heading="Alerts"
                    sub-heading="A list of all alerts you are subscribed to."
                />
            </column>
            <column class="is-5">
                <form>
                    <text-input v-model="filters.search" classes="has-addons is-rounded"
                        placeholder="Search for alerts...">
                        <template #right>
                            <div class="buttons has-addons is-rounded">
                                <submit-button @submit="runSearch" class="is-rounded">
                                    Search
                                </submit-button>
                                <action-button v-if="$route.query.search" @click="clearFilters">
                                    <icon icon="times" />
                                </action-button>
                                <router-link class="button is-rounded" :to="{
                                    name: 'create-alert'
                                }">
                                    <icon icon="plus"/>
                                    <span>Alert</span>
                                </router-link>
                            </div>
                        </template>
                    </text-input>
                </form>
            </column>
        </columns>
        <columns>
            <column>
                <div class="index-rows">
                    <div class="box is-marginless" v-for="alert in alerts.data" :key="alert.id">
                        <columns>
                            <column>
                                <router-link class="mr-2" :to="{
                                    name: 'edit-alert',
                                    params: {
                                        alert: alert.id
                                    }
                                }">
                                    {{ alert.type | replace('_', ' ') | ucwords }}
                                </router-link>
                                <small class="has-text-grey">Updated {{ alert.updated_at | asCalendar }}</small>
                            </column>
                            <column class="is-1 has-text-right">
                                {{ alert.events.length }}
                                Event<span v-if="alert.events.length !== 1">s</span>
                            </column>
                            <column class="is-1 has-text-right">
                                {{ alert.schedule.schedule | ucwords }}
                            </column>
                            <column class="is-1 has-text-right">
                                <outcome-tag :status="alert.status" />
                            </column>
                        </columns>
                    </div>
                </div>

                <pager v-if="alerts.data.length" :pageable="alerts" context="Equipment" jump-controls @nav="goToPage" />

                <no-items-to-display 
                    :item-count="alerts.data.length"
                    heading="There are no alerts available for display."
                />

            </column>
        </columns>
    </loader>
</template>
<script>
import { get } from '@/api/request'
export default {

    data: () => ({
        loading: true,
        filters: {
            search: ''
        },
        alerts: {
            data: []
        }
    }),

    created() {
        if (this.$route.query) {
            this.filters.search = this.$route.query.search
        }
        this.loadAlerts()
    },

    methods: {
        loadAlerts() {
            get('v1' + this.$route.fullPath, ({ data }) => {
                this.alerts = data
                this.loading = false
            }, () => {
                this.loading = false
            }, { params: this.filters })
        },
        goToPage(path) {
            this.$router.push(path)
        },
        runSearch() {
            this.$router.push({
                name: 'alerts',
                query: this.filters
            });
        },
        clearFilters() {
            this.filters.search = ''
            this.$router.push({
                name: 'alerts',
            });
        }
    },

    watch: {
        '$route': 'loadAlerts'
    }

}
</script>